#black_overlay {
	top: 0;
	left: 0;
	padding: 0;
	margin: 0;
	z-index: 999998;
	position: fixed;
	width: 100%;
	height: 100%;
	opacity: 0.8;
	background: rgb(0, 0, 0);
}

#browser-detection {
	background: #fff;
	color: #4F4F4F;
	position: fixed;
		_position: absolute;
	padding: 20px;
	font-size: 12px;
	font-family: "Lucida Grande", "Lucida Sans Unicode", Arial, Verdana, sans-serif;
	width: 420px;
	z-index: 999999;
	/*height: 320px;*/
	border: 1px solid #CCC;
}

.bd-title {
	display: block;
	color: #333;
	padding: 5px;
	padding-bottom: 0;
	margin: 0;
	font-family: Helvetica, Arial, sans-serif;
	font-weight: bold;
	font-size: 1.3em;
	margin-bottom: 0.5em;
}

.bd-notice {
}

.bd-notice2 {
	padding: 5px;
	margin: 0;
	text-align: center;
}

.bd-browsers-list {
	margin: 0;
	padding: 0;
	width: 100%;
	list-style: none;
	text-align: center;
}

.bd-browsers-list a {
	padding: 100px 10px 10px 10px;
	width: 115px;
	text-decoration: none;
	color: inherit;
	display: inline-block;
}

.bd-browsers-list a.firefox {
	background: url(/libs/browser-detection/firefox.gif) no-repeat center top;
}
.bd-browsers-list a.chrome {
	background: url(/libs/browser-detection/chrome.gif) no-repeat center top;
}
.bd-browsers-list a.msie {
	background: url(/libs/browser-detection/msie.gif) no-repeat center top;
}
.bd-browsers-list a.opera {
	background: url(/libs/browser-detection/opera.gif) no-repeat center top;
}
.bd-browsers-list a.safari {
	background: url(/libs/browser-detection/safari.gif) no-repeat center top;
}

.bd-skip-buttons {
	display: inline-block;
	width: 100%;
}
.bd-skip-buttons a {
	cursor: pointer;
	text-decoration: underline;
	color: inherit;
}
.bd-skip-buttons #browser-detection-remind-later {
	float: left;
}
.bd-skip-buttons #browser-detection-never-remind {
	float: right;
}