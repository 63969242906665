/* =========================================
   BASE
   ========================================= */
* {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;
    outline: none!important;
}

body {
	width: 100%;
	min-height: 100vh;
	height: 100%;
	padding-bottom: 112px;
	font-size: 16px;
	position: relative;
	background-color: #894b51;
	font-family: 'PT Sans', sans-serif!important;
}

.txt {
	line-height: 170%;
}

.txt h1, .txt h2, .txt h3, .txt h4, .txt h5, .txt h6 {
	color: #899163!important;
	margin-top: 0!important;
	font-family: 'PT Sans', sans-serif!important;
}

.txt h1 {
	font-size: 28px;
}

.txt h2 {
 	font-size: 26px;
}

.txt h3 {
 	font-size: 24px;
}

.txt h4 {
 	font-size: 22px;
}

.txt h5 {
 	font-size: 20px;
}

.txt h6 {
 	font-size: 18px;
}

.txt p,
.txt p span {
	color: #fff!important;
	font-family: 'PT Sans', sans-serif!important;
}

.txt a, 
.txt a span {
	display: inline!important;
	color: #899163!important;
	font-weight: bold!important;
	text-decoration: none!important;
	font-family: 'PT Sans', sans-serif!important;
}

.txt ol {
	padding: 0!important;
	margin: 0!important;
	padding-left: 15px!important;
	list-style: decimal!important;
	list-style-color: #899163!important;
	font-family: 'PT Sans', sans-serif!important;
}

.txt ol li {
    font-size: 16px!important;
    color: #fff!important;
    margin-bottom: 10px!important;
    padding-left: 25px!important;
    position: relative!important;
    list-style-type: none!important;
    counter-increment: a!important;
}

.txt ol li:after {
	content: counter(a) ".";
    position: absolute;
    left: -15px;
    color: #899163;
    top: -1px;
    font-size: 22px;
}

.txt ul {
	padding: 0;
	margin: 0;
	padding-left: 15px;
}

.txt ul li {
	color: #fff;
	font-size: 16px;
}

img {
	max-width: 100%;
}

/*	========================================
	Custom Selection
	======================================== */
::-webkit-selection {
    background: rgba(137,145,99,0.7);
    text-shadow: none;
    color: #fff;
}

::-moz-selection {
    background: rgba(137,145,99,0.7);
    text-shadow: none;
    color: #fff;
}

::selection {
    background: rgba(137,145,99,0.7);
    text-shadow: none;
    color: #fff;
}

/*	==========================================
	TOOLTIP
	========================================== */
.tooltip.fade {
    background-color: transparent!important;
}

.tooltip.fade .tooltip-inner {
    background-color: #899163!important;
    text-transform: uppercase;
    font-size: 12px;
    font-family: Calibri, sans-serif; 
}

.tooltip.fade .tooltip-arrow {
	border-top: solid 10px;
    border-top-color: #899163!important;
}

/* =========================================
   CORES
   ========================================= */
.bg_default {
	background-color: #894b51;
}

.c_green {
	color: #8a8f64;
}

.c_white {
	color: #fff;
}

/* ==========================================
   CALENDARIO
   =========================================== */
.calendar {
    background-color: transparent;
    color: #fff;
    width: 100%;
    border-bottom: 0;
    margin-top: 3px;
}

.mes {
    background: #8a8f64;
    text-align: center;
    width: 100%;
    color: #fff;
    padding: 5px 0;
    text-transform: uppercase;
    font-size: 24px;
}

.semana {
    text-align: center;
    line-height: 25px;
    height: 25px;
    width: 30px;
    font-size: 14px;
}

.dia {
    text-align: center;
    height: 25px;
    width: 30px;
    font-size: 20px;
    font-weight: 700;
    padding: 5px;
}

.dia span {
	display: inline-block;
}

.legenda {
	border-top: 0;
	overflow: auto;
	height: 90px;
}

.legenda::-webkit-scrollbar {
    width: 12px;
}

.legenda::-webkit-scrollbar-track {
    background-color: #8a8f64;
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.1);
    border-radius: 10px;
}

.legenda::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: #563C3F;
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.1);
}

.legenda span {
	display: block;
	color: #8A8F64;
	font-size: 16px;
	font-weight: 700;
	letter-spacing: 1px;
	padding: 0 5px;
}

.custom_col {
	border: solid 1px #8A8F64;
    padding-left: 3px;
    padding-right: 3px;
    min-height: 410px;
}

/* =========================================
   HEADER
   ========================================= */
header {
	position: relative;
	top: 15px;
}

.menu ul {
	padding: 0;
	margin: 0;
	list-style: none;
}

.menu ul li {
	display: inline-block;
}

.menu ul li i {
	opacity: 0;
}

.menu ul li a {
	display: block;
	color: #898e64;
	font-family: 'Exo', sans-serif;
	font-size: 18px;
	padding: 10px 0;
	text-decoration: none;
	-webkit-transition: all 0.3s ease-out;
    transition: all 0.3s ease-out;
}

.menu ul li a:hover {
	color: #fff;
	text-decoration: none;
}

.menu ul li a:hover i {
	opacity: 1;
}

.menu ul li.active a {
	color: #fff;
}

.menu ul li.active a i {
	opacity: 1;
}

.social-media {
	display: inline-block;
	float: right;
}

.social-media a {
	color: #899163;
	font-size: 25px;
	display: inline-block;
	padding: 0 5px;
	float: left;
	margin: 5px;
	-webkit-transition: all 0.3s ease-out;
    transition: all 0.3s ease-out;
}

.social-media a:hover,
.social-media a:focus {
	color: #899163;
	text-decoration: none;
}

.fa-revista {
	width: 30px;
	height: 35px;
	background: url(../img/icone-revista-wlasan.png) no-repeat scroll center center;
	background-size: 100%;
}

.fa-classroom {
	width: 30px;
	height: 35px;
	background: url(../img/icone-classroom-wlasan.png) no-repeat scroll center center;
	background-size: 100%;
}

.login span {
	font-size: 12px;
	font-family: 'Exo', sans-serif;
	display: block;
	text-transform: uppercase;
	color: #bec2ac;
	margin-bottom: 5px;
}

.login form input {
	display: block;
	height: 30px;
	line-height: 30px;
	margin-bottom: 2px;
	border: 0;
    box-shadow: none;
    background-color: #663b3f;
    color: #ad9c9e;
    font-family: 'Exo', sans-serif;
    padding: 0 10px;
    width: 100%;
}

.login form input::-webkit-input-placeholder {
	color: #ad9c9e;
	font-size: 12px;
	font-family: 'Exo', sans-serif;
	text-transform: uppercase;
}

.login form input::-moz-placeholder {
	color: #ad9c9e;
	font-size: 12px;
	font-family: 'Exo', sans-serif;
	text-transform: uppercase;
}

.login form input:-ms-input-placeholder {
	color: #ad9c9e;
	font-size: 12px;
	font-family: 'Exo', sans-serif;
	text-transform: uppercase;
}

.login form input:-moz-placeholder { 
	color: #ad9c9e;
	font-size: 12px;
	font-family: 'Exo', sans-serif;
	text-transform: uppercase;
}

.login label {
    position: relative;
    display: block;
    font-weight: normal;
    text-transform: uppercase;
}

.login form label i {
	position: absolute;
    right: 5px;
    top: 2px;
    font-size: 25px;
    color: #ad9c9e;
}

/* =========================================
   HOME
   ========================================= */
.banner {
	width: 100%;
	background-image: url(../img/image1.jpg);
	background-repeat: no-repeat;
	background-size: 100%;
	background-position: center;
	border: solid 1px red;
	height: 400px;
}

.swiper-container {
    width: 100%;
    height: 550px;
}

.swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #fff;
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
}

.swiper-pagination-bullet {
    width: 15px;
    height: 15px;
    display: inline-block;
    border-radius: 100%;
    background: #563536;
    opacity: 1;
}

.swiper-pagination-bullet-active {
    opacity: 1;
    background: #8A8F64;
}

.legend {
	position: relative;
	text-align: center;
	display: table-cell;
	vertical-align: middle;
	width: 100%;
	height: 100%;
	z-index: 9;
	text-decoration: none;
	opacity: 0;
	height: 1px;
	-webkit-transition: all 0.3s ease-out;  
	transition: all 0.3s ease-out; 
}

.legend:hover {
	opacity: 1;
	height: auto;
	text-decoration: none;
}

.legend:after{
	position: absolute;
	content: '';
	background-color: rgba(121,81,85,0.7);
	width: 100%;
	height: 100%;
	top: 0; 
	left: 0;
	z-index: -1;
}

.legend p {
	font-size: 16px;
	color: #e6c6c9;
}

.legend_button {
	display: inline-block;
	text-align: right;
	color: #8A8F64;
	font-size: 16px;
	text-decoration: none;
	text-transform: uppercase;
    border-right: solid 1px #8A8F64;
	border-left: solid 1px #8A8F64;
    padding: 8px;
    position: relative;
	-webkit-transition: all 0.3s ease-out;
	transition: all 0.3s ease-out;
}

.legend_button:before {
	content: '';
    position: absolute;
    border-bottom: 1px solid #8A8F64;
    border-top: 1px solid #8A8F64;
    width: 15px;
    top: 0;
    bottom: 0;
    left: 0;
}

.legend_button:after {
	content: '';
    position: absolute;
    border-bottom: 1px solid #8A8F64;
    border-top: 1px solid #8A8F64;
    width: 15px;
    top: 0;
    bottom: 0;
    right: 0;
}

.legend h1 {
    color: #e6c6c9;
    font-weight: 600;
    text-transform: uppercase;
    position: relative;
    margin-bottom: 30px;
    font-size: 40px;
    letter-spacing: 1px;
    display: inline-block;
    padding: 20px
}

.legend h1:after {
    position: absolute;
    content: '';
    border-bottom: 1px solid #e6c6c9;
    border-top: 1px solid #e6c6c9;
    bottom: 0;
    width: 0%;
    text-align: center;
    left: 50%;
    height: 100%;
    -ms-transform: translate(-50%, 0);
    -webkit-transform: translate(-50%, 0);
    transform: translate(-50%, 0);
	-webkit-transition: all 0.3s ease-out;
	transition: all 0.3s ease-out;
}

.legend:hover h1:after {
	width: 100%;
}

.bg_flower {
	background-image: url('../img/flower_bg.png');
	background-repeat: no-repeat;
	background-position: left center;
	background-size: inherit;
	background-color: #8a8f64;
	width: 100%;
	padding: 40px 50px;
}

.bg_flower h1 {
	color: #894b51;
	font-size: 40px;
}

.bg_flower p {
	color: #fff;
	font-size: 20px;
}

.box_education {
	width: 100%;
	height: 400px;
	text-align: center;
	font-weight: bold;
    letter-spacing: 1px;
    position: relative;
    z-index: 1;
    -webkit-transition: all 0.3s ease-out;
    transition: all 0.3s ease-out;
}

.box_education:hover {
	text-decoration: none;
	color: #fff;
}

.box_education:after {
	content: '';
	position: absolute;
	width: 100%;
	height: 100%;
	background-color: rgba(137,75,81,0.9);
	top: 0;
	left: 0;
	z-index: -1;
    -webkit-transition: all 0.3s ease-out;
    transition: all 0.3s ease-out;
}

.box_education:hover.box_education:after {
	background-color: rgba(255,255,255,0.1);
}

.box_education span {
	display: block;
	color: #8a8f64;
	font-size: 28px;
	text-align: center;
	text-transform: uppercase;
	text-shadow: 1px 1px 0 rgba(0,0,0,0.5);
}

.graduacao_bg {
	background-size: cover;
	background-repeat: no-repeat;
}

.graduacao_2_bg {
	background-image: url(../img/graduacao2.png);
	background-size: cover;
	background-repeat: no-repeat;
}

.inscrevase_bg {
	background-image: url(../img/vestibular.png);
	background-size: cover;
	background-repeat: no-repeat;
}

.icon-chapeu,
.icon-inscrever {
	background-repeat: no-repeat;
	background-position: center center;
    -webkit-filter: drop-shadow(1px 1px 0 rgba(0,0,0,0.4));
    filter: url(#drop-shadow);
    -ms-filter: "progid:DXImageTransform.Microsoft.Dropshadow(OffX=1, OffY=1, Color='#000')";
    filter: "progid:DXImageTransform.Microsoft.Dropshadow(OffX=1, OffY=1, Color='#000')";
	display: inline-block;
	width: 60px;
	height: 60px;
}

.installations {
	padding: 40px 50px;
	background-color: #8a8f64;
	background-image: url(../img/predio-colegio.png);
	width: 100%;
	background-position: right;
	background-repeat: no-repeat;
	background-size: contain;
}

.installations h2 {
	font-size: 25px;
	color: #fff;
	text-transform: uppercase;
	font-weight: bold;
}

.installations p {
	font-size: 16px;
	color: #fff;
}

.internship {
	background-color: #663b3f;
	width: 100%;
	padding: 20px 50px;
}

.internship h2 {
	font-size: 25px;
	color: #fff;
	text-transform: uppercase;
	font-weight: bold;
}

.internship p {
	font-size: 16px;
	color: #fff;
}

.maps {
	width: 100%;
	position: relative;
	height: 480px;
}

.map {
	height: 480px;
	width: 100%;
}

.map_contact {
	width: 100%;
	height: 330px;
	margin-bottom: 30px;
}

/* ======================================
   SOBRE
   ======================================= */
.banner_internals {
	background-repeat: no-repeat;
	width: 100%;
	height: 330px;
	background-size: cover;
	background-position: center center;
	margin-bottom: 30px;
}

.list-about ul {
	padding: 0;
	margin: 0;
}

.list-about ul li {
	list-style: none;
	position: relative;
	display: block;
}

.list-about ul li i {
    color: #fff;
    font-size: 24px;
    opacity: 0;
    position: absolute;
    left: 6px;
    top: 2px;
}

.list-about ul li a {
    text-decoration: none;
    color: #899163;
    font-size: 20px;
    text-transform: uppercase;
    padding: 0 0 15px 30px;
    display: block;
    font-weight: 700;
	-webkit-transition: all 0.3s ease-out;
	transition: all 0.3s ease-out;
}

.list-about ul li a:hover {
	color: #fff;
}

.list-about ul li a:hover i {
	opacity: 1;
}

.list-about ul li a:hover .list-about ul li i {
	opacity: 1;
}

.list-about ul li.active a span {
	color: #fff;
}

.list-about ul li.active i {
	opacity: 1;
}

/* =======================================
   CONTATO
========================================*/
.contact_form input,
.contact_form textarea {
	width: 100%;
    background-color: #663b3f;
    border: 0;
    padding: 0 10px;
    color: #ad9c9e;
    font-size: 14px;
    margin-bottom: 4px;
    font-weight: 600;
    border-bottom: solid 2px transparent;
	-webkit-transition: all 0.2s ease-out;
	transition: all 0.2s ease-out;
}

.contact_form input {
	height: 35px;
	line-height: 35px;
}

.contact_form textarea {
	padding: 10px;
}

.contact_form input:focus,
.contact_form textarea:focus { 
    border-bottom: solid 2px #777;
}

.contact_form span.error {
	display: block;
	color: #fff;
    margin: -4px 0 10px;
}

.btn_green,
.btn_vine {
    display: inline-block;
    font-size: 16px;
    text-decoration: none;
    text-transform: uppercase;
    border: 0;
    background-color: transparent;
    font-weight: bold;
    padding: 8px 20px;
    position: relative;
    text-align: center;
    -webkit-transition: all 0.5s ease-out;
    transition: all 0.5s ease-out;
    -webkit-transition-delay: 0.3s;
    transition-delay: 0.3s;
}

.btn_green {
    color: #8a8f64;
    border-right: solid 2px #8a8f64;
    border-left: solid 2px #8a8f64;
}

.btn_vine {
    color: #894B51;
    border-right: solid 2px #894B51;
    border-left: solid 2px #894B51;
}

.btn_green:before,
.btn_green:after,
.btn_vine:before,
.btn_vine:after {
	content: '';
    position: absolute;
    width: 15px;
    top: 0;
    bottom: 0;
    -webkit-transition: all 0.2s ease-out;
    transition: all 0.2s ease-out;
}

.btn_green:before,
.btn_green:after {
    border-bottom: 2px solid #8a8f64;
    border-top: 2px solid #8a8f64;
}

.btn_vine:before,
.btn_vine:after {
    border-bottom: 2px solid #894B51;
    border-top: 2px solid #894B51;
}

.btn_green:before,
.btn_vine:before {
    left: 0;
}

.btn_green:after,
.btn_vine:after {
    right: 0;
}

.btn_green:hover:after,
.btn_vine:hover:after,
.btn_green:hover:before,
.btn_vine:hover:before {
	width: 100%;
}

.btn_green:hover:before,
.btn_vine:hover:before {
	border-bottom-color: transparent;
}

.btn_green:hover:after,
.btn_vine:hover:after {
	border-top-color: transparent;
}

.btn_green:hover,
.btn_green:focus,
.btn_vine:hover,
.btn_vine:focus {
	color: #fff;
	text-decoration: none;
}

.btn_green:hover,
.btn_green:focus {
	background-color: #8A8F64;
}

.btn_vine:hover,
.btn_vine:focus {
	background-color: #894B51;
}

/* =======================================
   FOOTER
   ======================================= */
footer {
	position: absolute;
	bottom: 0;
	left: 0;
	width: 100%;
}

.foot {
	background-color: #663b3f;
	width: 100%;
	padding-top: 30px;
	padding-bottom: 15px;
}

.foot span {
    color: #fff;
    font-size: 15px;
    display: inline-block;
    float: left;
    position: relative;
    margin: 25px 20px 0 0;
}

.pin_footer {
	font-size: 6px;
    display: inline;
    margin: 0 10px;
    color: rgba(255,255,255,0.5);
}

/* 	======================================
    BOTÔES
	====================================== */
.btn_sign_up {
	color: #fff;
	text-transform: uppercase;
	font-size: 20px;
	background-color: #899163;
	padding: 20px;
	border-radius: 0;
	display: inline-block;
	position: relative;
	border: solid 2px #894b51;
	text-decoration: none;
	-webkit-transition: all 0.3s ease-out;
    transition: all 0.3s ease-out;
}

.btn_sign_up:hover,
.btn_sign_up:focus {
	text-decoration: none;
	color: #fff;
	border: solid 2px #899163;
	background-color: transparent;
}

.btn_sign_up:after {
	content: '';
	border: solid 2px #899163;
	top: -6px;
	left: -6px;
	bottom: -6px;
	right: -6px;
	position: absolute;
}

/* =========================================
   MENU MOBILE
   ========================================= */
.btn_menu_mobile {
    position: relative;
    display: none;
    padding: 20px;
	-webkit-transition: all 0.3s ease-out;
	transition: all 0.3s ease-out;
}

.btn_menu_mobile span {
	float: right;
	position: relative;
	height: 3px;
	width: 30px;
	border-radius: 4px;
	background-color: #8A8F64;
}

.btn_menu_mobile span:before,
.btn_menu_mobile span:after {
	position: absolute;
	content: "";
	left: 0;
	border-radius: 4px;
	height: 3px;
	width: 100%;
	-webkit-transition: all 0.3s ease-out;
	transition: all 0.3s ease-out;
	background-color: #8A8F64;
}

.btn_menu_mobile span:before {
	top: -8px;
}

.btn_menu_mobile span:after {
	top: calc(100% + 6px);
}

.btn_menu_mobile.active span {
	background: transparent;
}

.btn_menu_mobile.active span:after {
	top: 0;
	-webkit-transform: rotateZ(45deg);
	-ms-transform: rotateZ(45deg);
	transform: rotateZ(45deg);
	background-color: #8A8F64;
}

.btn_menu_mobile.active span:before {
	top: 0;
	-webkit-transform: rotateZ(-45deg);
	-ms-transform: rotateZ(-45deg);
	transform: rotateZ(-45deg);
	background-color: #8A8F64;
}

.mask_menu_mobile.active {
    visibility: visible;
    opacity: 1;
}

.mask_menu_mobile {
    position: fixed;
    width: 100%;
    height: 100%;
    background-color: rgba(137,75,81,0.9);
    z-index: 9;
    top: 0;
    left: 0;
    visibility: hidden;
    opacity: 0;
    -webkit-transition: all 0.3s ease-out;
    transition: all 0.3s ease-out;
}

/* 	=======================================
	PÁGINA DE ERRO
   	======================================= */
.top_erro {
	background-color: #ecedf2;
	width: 100%;
	height: 50vh;
	position: relative;
}

.top_erro:after {
	content: '';
	position: absolute;
	border-radius: 20%;
	width: 0;
	height: 0;
	bottom: -10px;
	left: calc(50% - 50px);
	border: 50px solid;
	border-color: #ecedf2;
	-ms-transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    z-index: 1;
}

.center_erro {
	background-color: #8a4b51;
	width: 100%;
	height: 30vh;
	position: relative;
}

.center_erro:before {
	content: '';
	position: absolute;
	border-radius: 20%;
	width: 0;
	height: 0;
	bottom: -10px;
	left: calc(50% - 50px);
	border: 50px solid;
	border-color: #8a4b51;
	-ms-transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    z-index: 1;
}

.center_erro h1 {
	font-size: 54px;
    text-align: center;
    text-transform: uppercase;
    color: #ecedf2;
    margin: 0;
}

.center_erro p {
	font-size: 26px;
	text-align: center;
	text-transform: uppercase;
	color: #ecedf2;
}

.bottom_erro {
	height: 20vh;
	background-image: url('../img/bg_erro.png');
	background-repeat: no-repeat;
	background-size: 100%;
	width: 100%;
	text-align: center;
}

.bottom_erro a {
	color: #8a4b51;
	text-decoration: none;
	text-transform: uppercase;
	font-size: 26px;
	display: inline-block;
	margin-top: 60px;
	-webkit-transition: all 0.3s ease-out;
	transition: all 0.3s ease-out;
}

/* =======================================
   HELPERS
   ======================================= */
.d_block {
	display: block;
}

.d_i_block {
	display: inline-block;
}

.d_table {
	display: table;
}

.d_t_cell {
	display: table-cell;
	vertical-align: middle;
}

.d_t_cell_bottom {
	display: table-cell;
	vertical-align: bottom;
}

.b_r_green {
	border-left: 1px solid #899163;
    padding-left: 40px;
}

.col_0 div[class*="col-"] {
    padding: 0;
}

.h_80 {
	height: 80px;
}

.h_90 {
	height: 90px;
}

.h_100 {
	height: 100%;
}

.w_100 {
	width: 100%;
}

.w_70 {
	width: 70%;
}

.w_30 {
	width: 30%;
}

.w_33 {
	width: 33.333%;
}

.m_0 {
	margin: 0;
}

.m_b_30 {
	margin-bottom: 30px;
}

.m_b_50 {
	margin-bottom: 50px;
}

.m_b_60 {
	margin-bottom: 60px;
}

.m_0_auto {
	margin: 0 auto;
}

.nav-tabs {
    border-bottom: 0;
}

.t_center {
	text-align: center;
}

.t_right {
	text-align: right;
}

.t_left {
	text-align: left;
}

.p_r_1 {
	padding-right: 2px;
}

.p_l_1 {
	padding-left: 2px;
}

.p_relative {
	position: relative;
}

.z_index {
	z-index: 9;
}

/* =======================================
   RESPONSIVE
   ======================================= */
@media (max-width: 1094px) {
	.menu ul li a {
		font-size: 17px;
	}
}

@media (max-width: 1037px) {
	.menu ul li a {
		font-size: 16px;
	}
}

@media(max-width: 991px) {
	.btn_menu_mobile {
		display: block;
	}

	.menu ul {
		display: none;
	}

	.menu ul li {
		display: block;
	}

	.menu ul li a {
		font-size: 18px;
	}

	.w_30_m {
		width: 30%;
	}

	.h_80 {
		height: auto;
	}
}

@media (min-width: 1200px) {
	.container {
	    width: 1170px!important;
	}
}

@media (min-width: 992px) {
	.container {
    	width: 100%;
	}
}

@media(max-width: 991px) {
	.btn_menu_mobile {
		display: block;
	}

	.menu {
		position: relative;
		z-index: 9999;
		text-align: center;
	}

	.menu ul {
		display: none;
	}

	.menu ul li {
		display: block;
	}

	.b_r_green {
	    border-left: 0;
	    padding-left: 0;
	    padding-top: 40px;
	    border-top: 1px solid #899163;
	    margin-top: 30px;
	}

}

@media (min-width: 768px) {
	.container {
	    width: 100%;
	}
}

@media (max-width: 768px) {
	.foot {
	    padding: 10px 0;
	}

	.d_none {
		display: none;
	}

	.bg_flower {
		background: #8a8f64;
		padding: 30px 20px;
	}

}

@media (max-width: 768px) {
	body {
		padding-bottom: 131px;
	}

	.bg_flower {
		background: #8a8f64;
		padding: 30px 20px;
	}

	.t_sm_center {
		text-align: center!important;
	}
}

@media (max-width: 689px) {
	.t_responsive {
		float: left;
		width: 100%;
	}

	.installations {
		background: #8a8f64;
		padding: 30px 20px;
	}

	.internship {
		padding: 30px 20px;
	}

	.box_education {
		height: auto;
		padding: 30px 0;
	}

	.b_r_green {
		padding-left: 5px;
	}

	.maps {
		height: 350px;
	}

	.map {
		height: 350px;
	}
}

@media (max-width: 450px) {
	body {
		padding-bottom: 151px;
	}

	.w_450 {
		width: 100%;
	}

	.h_xs_auto {
		height: auto;
	}
}

@media (max-width: 425px) {
	.banner_internals {
		height: 150px;
	}

	.bg_flower h1 {
		font-size: 30px
	}

	.bg_flower p {
		font-size: 18px
	}

	.t_responsive_425 {
		float: left;
		width: 100%;
	}

	.t_center_425 {
		text-align: center;
	}
}

@media (max-width: 400px) {
	body {
		padding-bottom: 171px;
	}
}